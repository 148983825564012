.mt60 {
  padding: 130px;
  display: flex;
  flex-direction: row;
}
.rightCol {
  width: 50%;
}
.titleStyle {
  font-family: "Poppins", "Work Sans", "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 92px;
  line-height: 89px;
  color: #ffffff;
}
.titleStyleYellow {
  color: #ffcf2c;
}
.RedefiningImage {
  padding-left: 30px;
  padding-top: 60px;
}
.loginBackground {
  background: linear-gradient(
    171.22deg,
    #191a1d 46.86%,
    rgba(116, 99, 44, 0.06) 175.21%
  );
  border-radius: 10px;
  /* padding: 50px; */
  /* text-align: center; */
  /* align-items: center; */
}
.logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  font-family: "Poppins", "Work Sans", "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 48px;
  color: #ffffff;
  padding-bottom: 50px;
}
.logininput {
  box-sizing: border-box;
  /* display: flex; */
  /* flex-direction: row; */
  align-items: center;
  padding: 8px 12px;
  width: 421px;
  height: 48px;
  background: transparent;
  border-radius: 6px;
  color: #ffffff;
  font-size: 16px;
  font-family: "Poppins", "Work Sans", "Helvetica";
  border: none;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}
.loginDiv {
  margin-top: 40px;
  display: flex;
  align-items: center;
}
.loginDiv1 {
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.usericons {
  background: rgba(255, 255, 255, 0.04);
  border-radius: 6px;
  height: 48px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  align-items: center;
  padding-left: 10px;
  font-size: 28px;
}
.forgotText {
  font-family: "Poppins", "Work Sans", "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  color: #ffcf2c;
  margin-top: 20px;
  /* width: 50%; */
}
.checkbox {
  text-align: left;
  margin-top: 20px;
  font-family: "Poppins", "Work Sans", "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.85);
  /* width: 50%; */
}
.loginButton {
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  /* width: 448px; */
  height: 48px;
  background: #ffcf2c;
  border: 1px solid #ffcf2c;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 6px;
  font-family: "Poppins", "Work Sans", "Helvetica";
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  margin-top: 30px;
}
.signUpText {
  font-family: "Poppins", "Work Sans", "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin-top: 20px;
}
.SignUpHeaad {
  font-family: "Poppins", "Work Sans", "Helvetica";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 24px;
  color: #ffffff;
  margin-top: 35px;
}
.loginText {
  font-family: "Poppins", "Work Sans", "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  color: #ffffffcc;
}
.resetPasswordSection {
  padding: 130px;
  text-align: center;
  align-items: center;
}
.resetloginBackground {
  background: linear-gradient(
    171.22deg,
    #191a1d 46.86%,
    rgba(116, 99, 44, 0.06) 175.21%
  );
  
}
.resetDiv {
  text-align: center;
  width: 100%;
  margin-left: 20px;
}
.errorMsg {
  color: #e84142;
  text-align: left;
  margin-top: 10px;
}
