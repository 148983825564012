.Dashboard {
  display: flex;
  min-height: 100vh;
}
.dashboardLeft {
  background-color: #17181b;
  text-align: center;
  min-height: 100vh; /* Full-height: remove this if you want "auto" height */
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: #111; /* Black */
  overflow-x: hidden;
  width: 20%;
}
.userImage {
  width: 100px;
  height: 100px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  color: #f2f2f2;
  font-size: 3.3rem;
  align-items: center;
  text-align: center;
}
.username {
  font-family: "Poppins", "Work Sans", "Helvetica";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 40px;
  color: #f2f2f2;
  margin-top: 20px;
}
.userEmail {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-top: -5px;
}
.sideTab {
  font-family: "Poppins", "Work Sans", "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.85);
  display: flex;
  align-items: center;
  padding: 18px 40px;
}
.usericons {
  margin-right: 20px;
}

.sideTabActive {
  font-family: "Poppins", "Work Sans", "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.85);
  display: flex;
  align-items: center;
  padding: 18px 40px;
  background: #ffcf2c1a;
  box-shadow: inset 8px 0px 0px #ffcf2c;
  border-left: 2px solid #ffcf2c;
}
.marqueeDiv {
  background: #ffcf2c1a;
  font-family: "Poppins", "Work Sans", "Helvetica";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 22px;
  color: #ffffffd9;
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
/* trade history css */
.tradeData {
  padding: 40px 0px 40px 60px;
  width: 80%;
  margin-left: 20%;
}

.tradeHeading {
  font-family: "Roboto", "Work Sans", "Helvetica";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  color: #f2f2f2;
  margin-top: 15px;
}
.aboveDiv {
  display: flex;
  margin-top: 2rem;
}
.tradePara {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #f2f2f2;
  width: 100%;
}
.investedBox {
  width: 33.33%;
  background: #17181b;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  padding: 20px 20px;
  margin-right: 35px;
}
.InvestedHead {
  font-family: "Poppins", "Work Sans", "Helvetica";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.8);
}
.InvestedAmount {
  font-family: "Poppins", "Work Sans", "Helvetica";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 32px;
  color: #ffffff;
  margin-top: 1rem;
}
.dataContent {
  font-family: "Poppins", "Work Sans", "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  color: #f2f2f2;
  padding: 10px;
  width: 12%;
}
.greenColorText {
  color: #18b13f;
}
.redColorText {
  color: #eb323f;
}
.InvestedHeadText {
  font-family: "Poppins", "Work Sans", "Helvetica";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.8);
  margin-top: 30px;
}
.InvestedHeadTextActive {
  font-family: "Poppins", "Work Sans", "Helvetica";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 22px;
  color: rgba(255, 255, 255, 1);
  margin-top: 30px;
}
.tradeDiv {
  display: flex;
  background: #17181b;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  margin-top: 20px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom: none;
  margin-right: 35px;
}
.maptradeData {
  display: flex;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-top: none;
  margin-right: 35px;
}
.dataHeading {
  font-family: "Poppins", "Work Sans", "Helvetica";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  color: #f2c94c;
  padding: 10px;
  width: 12%;
}
.dataHeading2 {
  font-family: "Poppins", "Work Sans", "Helvetica";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  color: #f2c94c;
  padding: 10px;
  width: 17%;
}
.dataHeading3 {
  font-family: "Poppins", "Work Sans", "Helvetica";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  color: #f2c94c;
  padding: 10px;
  width: 32%;
}
.dataContent2 {
  font-family: "Poppins", "Work Sans", "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  color: #f2f2f2;
  padding: 10px;
  width: 17%;
}

.dataContent3 {
  font-family: "Poppins", "Work Sans", "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  color: #f2f2f2;
  padding: 10px;
  width: 32%;
}

/* Exchange Integrate css */
.exchangeDiv {
  
  background-image: url("../Resources/Card_background.svg");
  background-repeat: no-repeat;
  background-position: center;
  
}
.exchangeSpace {
  background: #17181b;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  padding: 70px;
}
.exchnageHeading {
  font-family: "Roboto", "Work Sans", "Helvetica";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  color: #f2f2f2;
}
.exchnageDataName {
  width: 33%;
  display: flex;
  align-items: center;
  font-family: "Poppins", "Work Sans", "Helvetica";
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 50px;
  color: #ffffff;
  margin-top: 30px;
}
.exchngaeImg {
  width: 40px;
  height: 40px;
  margin-right: 15px;
}
.btcText {
  font-family: "Poppins", "Work Sans", "Helvetica";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  margin-left: 10px;
}
.dropdownBox {
  align-items: center;
  text-align: center;
  padding: 10px 20px;
  /* width: 210px; */
  height: 48px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  /* background: none; */
}
.dropdownBox2 {
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
}
.dropdownBoxSelect {
  background: #17181b;
  color: #f2f2f2;
  border-radius: 4px;
  background: none;
  font-family: "Roboto", "Work Sans", "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  /* width: 100%; */
}
.dropDownSelection {
  font-family: "Roboto", "Work Sans", "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  
  color: #f2f2f2;
}
.inputDate {
  box-sizing: border-box;
  align-items: center;
  padding: 8px 12px;
  /* width: 190px; */
  height: 48px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: none;
  border-radius: 4px;
  color: #f2f2f2;
  font-size: 14px;
  font-family: "Roboto", "Work Sans", "Helvetica";
}
.inputDate::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
.filterDiv {
  margin-right: 20px;
}
.leftSideBottomContent {
  color: #ffcf2c;
  position: absolute;
  bottom: 15px;
  border-top: 2px solid rgba(255, 255, 255, 0.08);
  font-family: "Poppins", "Work Sans", "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  width: 100%;
  padding-top: 15px;
}
.imagecss {
  align-items: center;
  text-align: center;
}

@media (max-width: 1200px) {
  .exchangeDiv {
  
    background-image: none;
    background-repeat: no-repeat;
    background-position: center;
    
  }
}