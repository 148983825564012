.labelText {
  font-family: "Roboto", "Work Sans", "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #f2f2f2;

  margin-right: 20px;
}
.userSpaceDiv {
  background: #17181b;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;
}
.userSetDiv {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.loginDiv {
  width: 50%;
  display: flex;
  align-items: center;
}
.saveButton {
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  width: 251.59px;
  height: 48px;
  background: #ffcf2c;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 6px;
  font-family: "Poppins", "Work Sans", "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #000000;
}
.cancelButton {
  color: #ffffff;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.22);
  margin-left: 20px;
}
.buttonDiv {
  display: flex;
  width: 100%;
  margin-top: 70px;
  justify-content: space-between;
}
.changetextDiv {
  /* border: 1px solid rgba(255, 255, 255, 0.2); */
  background: none;
  border-radius: 6px;
  height: 48px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  padding: 10px;
  font-size: 14px;
  color: #ffcf2c;
  border-left: none;
}
.logininput {
  box-sizing: border-box;
  align-items: center;
  padding: 8px 12px;
  height: 48px;
  /* border: 1px solid rgba(255, 255, 255, 0.2); */
  background: none;
  border-radius: 6px;
  color: #ffffff;
  font-size: 16px;
  font-family: "Poppins", "Work Sans", "Helvetica";
  border-left: none;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}
.inputBox2 {
  box-sizing: border-box;
  align-items: center;
  padding: 8px 16px;
  
  height: 48px;
  
  background: none;
  color: #ffffff;
  font-size: 16px;
  font-family: "Poppins", "Work Sans", "Helvetica";
}
.inputBox3 {
  box-sizing: border-box;
  align-items: center;
  padding: 8px 16px;
  height: 48px;

  background: none;
  color: #ffffff;
  font-size: 16px;
  font-family: "Poppins", "Work Sans", "Helvetica";
  border-left: none;
  border-right: none;
}
.tetherIcon {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 48px;
  background: none;
  border-radius: 6px;
  color: #ffffff;
  font-size: 16px;
  font-family: "Poppins", "Work Sans", "Helvetica";
  border-right: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;

}
.inputBox4 {
  box-sizing: border-box;
  align-items: center;
  /* padding: 2px 16px; */
  /* padding-left: 8px; */

  height: 48px;
  background: none;
  border-radius: 6px;
  color: #ffffff;
  font-size: 16px;
  font-family: "Poppins", "Work Sans", "Helvetica";
  border-left: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.inputBox {
  box-sizing: border-box;
  align-items: center;
  padding: 8px 16px;
  width: 100%;
  height: 48px;
  /* border: 1px solid rgba(255, 255, 255, 0.2); */
  background: none;
  border-radius: 6px;
  color: #ffffff;
  font-size: 16px;
  font-family: "Poppins", "Work Sans", "Helvetica";
}
.inputFile {
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 6px;
}
.usericons2 {
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: none;
  border-right: none;
}
.successMsg {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #18b13f;
  margin-top: 30px;
}
.loginDivDisable {
  background-color: #ffcf2c1a;
  cursor: not-allowed;
  border-radius: 6px;
}
.optionBox {
  background-color: #17181b;
}
.redTextColor {
  color: rgba(235, 50, 63, 1);
}
